import React from 'react';
import Layout from 'customer/components/Layout/Layout';
import NotFound from 'customer/components/not-found/NotFound';

const title = 'Page Not Found';

function action() {
  return {
    chunks: ['not-found'],
    title,
    protected: false,
    component: (
      <Layout>
        <NotFound title={title} />
      </Layout>
    ),
    status: 404,
  };
}

export default action;
