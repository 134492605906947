import useStyles from 'isomorphic-style-loader-react18/useStyles';
import React from 'react';
import s from './NotFound.less';

interface NotFoundProps {
  title: string;
}

const NotFound: React.FC<NotFoundProps> = ({ title }) => {
  useStyles(s);
  return (
    <div className={s.root}>
      <div className={s.container}>
        <h1>{title}</h1>
        <p>Sorry, the page you were trying to view does not exist.</p>
      </div>
    </div>
  );
};

export default NotFound;
